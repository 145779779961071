import React, { useState, useRef, useEffect } from 'react';
import styles from './AnalyticsPage.module.css'; // Импорт стилей
import Calendar from './../../Calendar/Calendar';
import Table from './../../Table/Table';
import { getAppointmentsRequest, getStats, getTime } from './../../../api/requests';

function AnalyticsPage() {
  const [date, setDate] = useState(new Date())
  const [resNames, setResNames] = useState([])
  const [resData, setResData] = useState([])
  const [jobs, setJobs] = useState([])
  const [activeEl, setActiveEl] = useState([
    {
        "id": 5,
        "companyid": 3,
        "workshiftid": 1,
        "norm": "1",
        "from": "09:00",
        "to": "10:00",
        "isActive": false
    },
    {
        "id": 6,
        "companyid": 3,
        "workshiftid": 2,
        "norm": "1",
        "from": "06:00",
        "to": "07:00",
        "isActive": false
    },
    {
        "id": 7,
        "companyid": 3,
        "workshiftid": 3,
        "norm": "1",
        "from": "07:00",
        "to": "08:00",
        "isActive": false
    },
    {
        "id": 8,
        "companyid": 3,
        "workshiftid": 4,
        "norm": "1",
        "from": "08:00",
        "to": "09:00",
        "isActive": false
    }
  ])

  let setDateFunc = (val) => {
    setDate(val)
  }

  let fetchData = async () => {
    let resTime = await getTime() 
    console.log(resTime)
    setActiveEl(await getTime())

    let resJobs = await getAppointmentsRequest()
    
    setJobs(resJobs)
    let res = await getStats(`${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`)
    console.log(res)
    setResNames(Object.keys(res));
    setResData(Object.values(res));
  }

  useEffect(() => {
    fetchData()
  }, [date]);

  const handleClick = (title) => {
    let openedElems = JSON.parse(localStorage.getItem('openedElems'))
    if(!openedElems) {
      openedElems = []
    }
    if(openedElems.includes(title)) {
      openedElems = openedElems.filter((el) => el !== title)
    } else {
      openedElems.push(title)
    }
    localStorage.setItem('openedElems', JSON.stringify(openedElems))
  };

  const days = [
    { key: 1, label: "У" },
    { key: 2, label: "Д" },
    { key: 3, label: "В" },
    { key: 0, label: "Н" },
    { key: "sum", label: "Сум. часов" },
    { key: "salary", label: "ЗП. руб." },
    { key: "overtime_hours", label: "Перер. ч." },
    { key: "overtime_salary", label: "Перер. руб." },
    { key: "total_salary", label: "ЗП" }
  ];
  
  // Найти индекс первого и последнего активного элемента
  let firstVisibleIndex = days.findIndex(({ key }) => activeEl[key]?.isActive);
  let lastVisibleIndex = days.length - 1;
  
  // Если все скрыты, то применяем к "Сум. часов"
  if (firstVisibleIndex === -1) {
    firstVisibleIndex = days.findIndex(({ key }) => key === "sum");
  }

  return (
      <>
        <div className={styles.main_header}>
          <Calendar setDateFunc={setDateFunc} />
          <div className={styles.days_container}>
            {days.map(({ key, label }, index) => (
              <div id={`top_1_index_${index}`} key={key}>
                <div
                  className={styles.day_container}
                  style={{
                    display: activeEl[key]?.isActive === false ? "none" : "",
                    borderLeft: index === firstVisibleIndex ? "1px solid rgb(99, 102, 241)" : "",
                    borderRadius: 
                      index === firstVisibleIndex ? "8px 0 0 8px" : 
                      index === lastVisibleIndex ? "0px 8px 8px 0px" : ""
                  }}
                >
                  {label}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.main_content}>
          {resData.length > 0 && resData.map((el, index) => {
            return (
              <span onClick={() => handleClick(resNames[index])}>
                <Table index={index} key={resNames[index]} JobTitleName={resNames[index]} leftPart={el} topPart={jobs.find((job) => job.name === resNames[index]).hourrates} widthPart="117px" tableNumber='1' activeEl={activeEl}/>
              </span>
            )
          })}
        </div>
      </>
  );
}

export default AnalyticsPage;
