import axios from 'axios';

// Создаем экземпляр axios
const axiosInstance = axios.create({
  baseURL: 'https://api.k-system-k.ru',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Функция для получения токенов из локального хранилища
const getRefreshToken = () => localStorage.getItem('refresh_token');

// Функция для выхода и редиректа на /login
const redirectToLogin = () => {
  console.log('Редирект на /login...');
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  window.location.replace('/login'); // Более жесткий редирект
};

// Добавляем интерцептор для обработки ошибок
axiosInstance.interceptors.response.use(
  (response) => response, // Успешный ответ
  async (error) => {
    if (error.config?.skipAuthInterceptor) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      if (window.location.pathname === '/login') {
        return Promise.reject(error);
      }

      const refreshToken = getRefreshToken();
      if (!refreshToken) {
        redirectToLogin();
        return Promise.reject(error);
      }

      try {
        const refreshResponse = await axios.post('https://api.k-system-k.ru/auth/refresh', {}, {
          headers: { Authorization: `Bearer ${refreshToken}` },
        });

        if (refreshResponse.status !== 200 || !refreshResponse.data?.accessToken) {
          throw new Error('Не удалось обновить токен');
        }

        // Обновляем токены
        localStorage.setItem('access_token', refreshResponse.data.accessToken);
        localStorage.setItem('refresh_token', refreshResponse.data.refreshToken);

        // Обновляем заголовки
        axiosInstance.defaults.headers.Authorization = `Bearer ${refreshResponse.data.accessToken}`;

        // Повторяем запрос с новым токеном
        const originalRequest = { ...error.config };
        originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.accessToken}`;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('Ошибка обновления токена:', refreshError);
        redirectToLogin();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
