import styles from './ProfilePage.module.css';
import { useEffect, useState } from 'react';
import Calendar from '../../Calendar/Calendar';
import user_icon from '../../../assets/MainPage/user_icon.png'
import { changeUserProfile, getUserProfile } from '../../../api/requests';
import { useNavigate } from 'react-router-dom';

function ProfilePage() {
  const navigate = useNavigate();

  const [profileInfo, setProfileInfo] = useState({
    firstname: '',
    secondname: '',
    lastname: '',
    birthdate: null,
    email: '',
    phone: '',
    number1c: '',
    passportNumber: '',
    city: '',
    street: '',
    houseNumber: '',
    building: '',
  });
  
  useEffect(() => {
    let fetchData = async () => {
      let res = await getUserProfile()
      setProfileInfo(res)
    }
    
    fetchData()
  }, []);

  // Функция для изменения значений в profileInfo
  const handleProfileChange = (field, value) => {
    setProfileInfo((prevInfo) => ({
      ...prevInfo,
      [field]: value
    }));
  };

  const changeProfile = async() => {
    await changeUserProfile(profileInfo)
  };

  const setDateFunc = (val) => {
    let date = val.getDate().toString().padStart(2, '0') + '.' + (val.getMonth() + 1).toString().padStart(2, '0') + '.' + val.getFullYear();
    if (profileInfo.birthdate !== date) {
        handleProfileChange('birthdate', date);
    }
  };

  const logOut = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')

    navigate('/login');
  };

  return (
    <div className={styles.container}>
      <div className={styles.info_container}>
        <div className={styles.info_container_title}>
          <div className={styles.title_info_container}>
            <div className={styles.title_info_img}>
              <img src={user_icon} alt="" />
            </div>
          </div>
          <div className={styles.title_role_container} />
        </div>
        <div className={styles.info_inputs_container}>
          <div className={styles.info_input_container}>
            <div className={styles.info_input}>
              <span className={styles.info_input_span}>Телефон</span>
              <input 
                type="text" 
                value={profileInfo.phone} 
                placeholder='8 (___) ___ - __ - __' 
                onChange={(e) => handleProfileChange('phone', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span className={styles.info_input_span}>Email</span>
              <input 
                type="text" 
                value={profileInfo.email} 
                placeholder='Email' 
                onChange={(e) => handleProfileChange('email', e.target.value)} 
              />
            </div>
          </div>
          <div className={styles.info_input_container}>
            <div className={styles.info_input}>
              <span className={styles.info_input_span}>Дата рождения</span>
              <Calendar setDateFunc={setDateFunc} isInput={true}  dateFromMain={(profileInfo.birthdate && profileInfo.birthdate !== 'NaN.NaN.NaN' && profileInfo.birthdate) || 'null'}/>
            </div>
            <div className={styles.info_input}>
              <span className={styles.info_input_span}>Номер паспорта</span>
              <input 
                type="text" 
                value={profileInfo.passportNumber} 
                onChange={(e) => handleProfileChange('passportNumber', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span className={styles.info_input_span}>Номер 1С</span>
              <input 
                type="text" 
                value={profileInfo.number1c} 
                onChange={(e) => handleProfileChange('number1c', e.target.value)} 
              />
            </div>
          </div>
          <div className={styles.info_input_container}>
            <div className={styles.info_input}>
              <span className={styles.info_input_span}>Адрес</span>
              <input 
                type="text" 
                value={profileInfo.city} 
                placeholder='Город' 
                onChange={(e) => handleProfileChange('city', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span className={styles.info_input_span}></span>
              <input 
                type="text" 
                value={profileInfo.street} 
                placeholder='Улица' 
                onChange={(e) => handleProfileChange('street', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span className={styles.info_input_span}></span>
              <input 
                type="text" 
                value={profileInfo.houseNumber} 
                placeholder='Номер дома' 
                onChange={(e) => handleProfileChange('houseNumber', e.target.value)} 
              />
            </div>
            <div className={styles.info_input}>
              <span className={styles.info_input_span}></span>
              <input 
                type="text" 
                value={profileInfo.building} 
                placeholder='Корпус' 
                onChange={(e) => handleProfileChange('building', e.target.value)} 
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.daytable_container}>
        <DayTable />
      </div>
      <div className={styles.rate_container}>
        <div className={styles.rate_container_title}>Ставки</div>
        <div className={styles.rate_individual_container}>
          <div className={styles.rate_individual_variant}>
            <span>Повар</span>
            <span>Шеф повар</span>
          </div>
          <div className={styles.rate_individual}>
            <input type="checkbox" />
            Индивидуальные условия
          </div>
        </div>
        <TimeRate />
      </div> */}
      <div className={styles.buttons_container}>
        <button className={styles.buttons_container_button}>Удалить аккаунт</button>
        <button className={styles.buttons_container_button} onClick={logOut}>Выйти из аккаунта</button>
        <button onClick={changeProfile}>Сохранить</button>
      </div>
    </div>
  );
}

export default ProfilePage;
