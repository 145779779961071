import show_password from "../../../assets/LoginPage/show_password.svg";
import hide_password from "../../../assets/LoginPage/hide_password.svg";
import error_icon from "../../../assets/LoginPage/error_icon.svg";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { resetPassword } from "../../../api/requests";

function NewPassword({ styles }) {
    const navigate = useNavigate();

    const [firstPassword, setFirstPassword] = useState('');
    const [secondPassword, setSecondPassword] = useState('');
    const [showFirstPassword, setShowFirstPassword] = useState(true);
    const [showSecondPassword, setShowSecondPassword] = useState(true);
    let [email, setEmail] = useState('')

    const [secondPasswordError, setSecondPasswordError] = useState('');

    useEffect(() => {
        setEmail(localStorage.getItem('email'))
        localStorage.removeItem('email')
    }, []);

    let newPasswordCreate = async () => {
        setSecondPasswordError('');
        if(!firstPassword || !secondPassword) {
            setSecondPasswordError('Введите пароли');
            return;
        } else if(firstPassword !== secondPassword) {
            setSecondPasswordError('Пароли не совпадают');
            return;
        }

        let data = {
            email,
            password: firstPassword
        }
        
        let res = await resetPassword(data)
        if(!res.error) {
            navigate('/login');
        }
    }

    return (
        <>
            <div className={styles.login_title}>
                Восстановление пароля
            </div>
            {/* Первое поле для нового пароля */}
            <div className={styles.input_container}>
                <span>Пароль</span>
                <input
                    value={firstPassword}
                    onChange={(e) => setFirstPassword(e.target.value)}
                    type={showFirstPassword ? "password" : "text"}
                    placeholder="Введите пароль"
                    name="password"
                />
                <img className={styles.show_password_icon} onClick={() => setShowFirstPassword(!showFirstPassword)} style={{ cursor: "pointer" }} src={showFirstPassword ? show_password : hide_password} alt="toggle password visibility"/>
            </div>
            {/* Второе поле для подтверждения пароля */}
            <div className={styles.input_container}>
                <span>Подтвердите пароль</span>
                <input
                    value={secondPassword}
                    onChange={(e) => setSecondPassword(e.target.value)}
                    className={secondPasswordError ? styles.input_error : ""}
                    type={showSecondPassword ? "password" : "text"}
                    placeholder="Введите пароль"
                    name="confirmPassword"
                />
                <img className={styles.show_password_icon} onClick={() => setShowSecondPassword(!showSecondPassword)} style={{ cursor: "pointer" }} src={showSecondPassword ? show_password : hide_password} alt="toggle password visibility"/>
                {secondPasswordError && (
                    <div className={styles.error_container}>
                        <img src={error_icon} className={styles.error_icon} alt="error" /> {secondPasswordError}
                    </div>
                )}
            </div>

            <button onClick={(e) => {
                e.preventDefault();
                newPasswordCreate();
            }}>
                Сохранить
            </button>
            <span style={{ cursor: "pointer" }}>
                <NavLink to="/login">
                    Войти в личный кабинет
                </NavLink>
            </span>
        </>
    );
}

export default NewPassword;
