import React from 'react';
import styles from './StatisticsPage.module.css'; // Импорт стилей
import { NavLink } from 'react-router-dom';
import AnalyticsPage from './../../components/StatisticsPage/AnalyticsPage/AnalyticsPage';

function StatisticsPage() {
  return (
    <div className={styles.main_container}>
      <div className={styles.choose_container}>
        <NavLink
        to="/statistics"
        style={({ isActive }) => ({
            color: isActive && window.location.pathname === "/statistics" ? '#6366F1' : '#8893A7',
            fill: isActive && window.location.pathname === "/statistics" ? '#6366F1' : '#8893A7',
            textDecoration: isActive && window.location.pathname === "/statistics" ? 'underline' : '',
        })}>
          Аналитика
        </NavLink>
        <NavLink
        to="/statistics/reports"
        style={({ isActive }) => ({
            color: isActive ? '#6366F1' : '#8893A7',
            fill: isActive ? '#6366F1' : '#8893A7',
            textDecoration: isActive ? 'underline' : '',
        })}>
          Отчеты
        </NavLink>
      </div>
      {window.location.pathname === "/statistics" ?
      <>
        <AnalyticsPage />
      </>
      :
      <></>
      }
    </div>
  );
}

export default StatisticsPage;
