import { useState, useEffect } from 'react';
import DayTable from './../DayTable/DayTable';
import styles from './ParametersPage.module.css'
import { getTime, resetTime } from '../../../api/requests';
import Checkbox from './../../Checkbox/Checkbox';

function ParametersPage() {
  const [error, setError] = useState('')
  const [time, setTime] = useState({
    first: { first: '08:30', second: '10:30' },
    second: { first: '10:30', second: '12:30' },
    third: { first: '12:30', second: '14:30' },
    fourth: { first: '14:30', second: '16:30' },
  });
  const [morning, setMorning] = useState(false)
  const [day, setDay] = useState(false)
  const [evening, setEvening] = useState(false)
  const [night, setNight] = useState(false)


  const order = [3, 0, 1, 2];

  // Функция для преобразования данных в нужный формат
  const transformShifts = (shiftData) => {
    const keys = ["first", "second", "third", "fourth"];

    return shiftData.reduce((acc, shift, index) => {
      const newIndex = order[index];  // Новый индекс
      const key = keys[newIndex];      // Используем его для соответствующего ключа

      acc[key] = { first: shift.from, second: shift.to };  // Заполняем объект
      return acc;
    }, {});
  }

  useEffect(() => {
    const fetchData = async () => {
      let res = await getTime();
      
      if(res.length !== 0) {
        setTime(transformShifts(res))
        res.forEach((element, index) => {
          if(index === 0) {
            setNight(element.isActive)
          } else if(index === 1) {
            setMorning(element.isActive)
          } else if(index === 2) {
            setDay(element.isActive)
          } else if(index === 3) {
            setEvening(element.isActive)
          }
        });
      }
    };

    fetchData()
  }, []);


  const handleTimeChange = (period, key, value) => {
    setTime(prevTime => ({
      ...prevTime,
      [period]: {
        ...prevTime[period],
        [key]: value
      }
    }));
  };

  function calculateTimeDifference(start, end, forData = false) {
    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);
  
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;
  
    let differenceInMinutes = endTotalMinutes - startTotalMinutes;
  
    if (differenceInMinutes < 0) {
      differenceInMinutes += 24 * 60;
    }
  
    const hours = Math.floor(differenceInMinutes / 60);
    const minutes = (differenceInMinutes % 60).toString().padStart(2, '0');
  
    let res = forData ? +`${hours}.${minutes}` : `${hours} ч. ${minutes} мин.`;
    return res;
  }

  let saveTime = () => {
    setError('')
    let data = {
      nightnorms: {
          from: time.fourth.first,
          to: time.fourth.second,
          norm: calculateTimeDifference(time.fourth.first, time.fourth.second, true),
          isActive: night ? true : false
      },
      morningnorms: {
          from: time.first.first,
          to: time.first.second,
          norm: calculateTimeDifference(time.first.first, time.first.second, true),
          isActive: morning ? true : false
      },
      daynorms: {
          from: time.second.first,
          to: time.second.second,
          norm: calculateTimeDifference(time.second.first, time.second.second, true),
          isActive: day ? true : false
      },
      eveningnorms: {
          from: time.third.first,
          to: time.third.second,
          norm: calculateTimeDifference(time.third.first, time.third.second, true),
          isActive: evening ? true : false
      }
    }

    // const totalNorm = data.nightnorms.norm + data.morningnorms.norm + data.daynorms.norm + data.eveningnorms.norm;

    // if (totalNorm > 24) {
    //   setError('Общее количество часов больше 24')
    // } else {
      resetTime(data)
    // }
  }

  return (
    <div className={styles.container}>
      <DayTable time={time} morningIsActive={morning} dayIsActive={day} eveningIsActive={evening} nightIsActive={night}/>

      <div className={styles.times_container}>
        <div className={styles.time_container}>
          <div className={styles.time_container_title}>Утро <span style={{display: 'block', marginTop: '18px'}}><Checkbox value={morning} valueChange={() => setMorning(!morning)}/></span></div>
          <div className={styles.time_container_input}>
            <span>Период (утро)</span>
            <div className={styles.time_input}>
              C
              <input
                type="time"
                style={{ marginRight: '5px' }}
                value={time.first.first}
                onChange={(e) => handleTimeChange('first', 'first', e.target.value)}
              />
              До 
              <input
                type="time"
                value={time.first.second}
                onChange={(e) => handleTimeChange('first', 'second', e.target.value)}
              />
            </div>
          </div>
          <div className={styles.time_container_input}>
            <span>Кол. часов (утро)</span>
            <input type="text" disabled value={calculateTimeDifference(time.first.first, time.first.second)}/>
          </div>
        </div>
        <div className={styles.time_container}>
          <div className={styles.time_container_title}>День <span style={{display: 'block', marginTop: '18px'}}><Checkbox value={day} valueChange={() => setDay(!day)}/></span></div>
          <div className={styles.time_container_input}>
            <span>Период (день)</span>
            <div className={styles.time_input}>
              C
              <input
                type="time"
                style={{ marginRight: '5px' }}
                value={time.second.first}
                onChange={(e) => handleTimeChange('second', 'first', e.target.value)}
              />
              До 
              <input
                type="time"
                value={time.second.second}
                onChange={(e) => handleTimeChange('second', 'second', e.target.value)}
              />
            </div>
          </div>
          <div className={styles.time_container_input}>
            <span>Кол. часов (день)</span>
            <input type="text" disabled value={calculateTimeDifference(time.second.first, time.second.second)}/>
          </div>
        </div>
        <div className={styles.time_container}>
          <div className={styles.time_container_title}>Вечер <span style={{display: 'block', marginTop: '18px'}}><Checkbox value={evening} valueChange={() => setEvening(!evening)}/></span></div>
          <div className={styles.time_container_input}>
            <span>Период (вечер)</span>
            <div className={styles.time_input}>
              C
              <input
                type="time"
                style={{ marginRight: '5px' }}
                value={time.third.first}
                onChange={(e) => handleTimeChange('third', 'first', e.target.value)}
              />
              До 
              <input
                type="time"
                value={time.third.second}
                onChange={(e) => handleTimeChange('third', 'second', e.target.value)}
              />
            </div>
          </div>
          <div className={styles.time_container_input}>
            <span>Кол. часов (вечер)</span>
            <input type="text" disabled value={calculateTimeDifference(time.third.first, time.third.second)}/>
          </div>
        </div>
        <div className={styles.time_container}>
          <div className={styles.time_container_title}>Ночь <span style={{display: 'block', marginTop: '18px'}}><Checkbox value={night} valueChange={() => setNight(!night)}/></span></div>
          <div className={styles.time_container_input}>
            <span>Период (ночь)</span>
            <div className={styles.time_input}>
              C
              <input
                type="time"
                style={{ marginRight: '5px' }}
                value={time.fourth.first}
                onChange={(e) => handleTimeChange('fourth', 'first', e.target.value)}
              />
              До 
              <input
                type="time"
                value={time.fourth.second}
                onChange={(e) => handleTimeChange('fourth', 'second', e.target.value)}
              />
            </div>
          </div>
          <div className={styles.time_container_input}>
            <span>Кол. часов (ночь)</span>
            <input disabled type="text" value={calculateTimeDifference(time.fourth.first, time.fourth.second)}/>
          </div>
        </div>
      </div>
      <div className={styles.button_container}>
        {error && <div className={styles.error}>{error}</div>}
        <button onClick={saveTime}>Сохранить</button>
      </div>
    </div>
  );
}

export default ParametersPage;
