import show_password from "../../../assets/LoginPage/show_password.svg";
import hide_password from "../../../assets/LoginPage/hide_password.svg";
import error_icon from "../../../assets/LoginPage/error_icon.svg";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { authRequest } from "../../../api/requests";

function SignIn({ styles }) {
  const [showPassword, setShowPassword] = useState(true);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(false); // Для отображения ошибки капчи

  const navigate = useNavigate()

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError(false); // Убираем сообщение об ошибке при успешной валидации
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение формы

    if (!captchaValue) {
      setCaptchaError(true); // Если капча не пройдена, показываем ошибку
      return;
    }
  };

  async function handleSubmitSignIn() {
    setLoginError(false)
    setPasswordError(false)
    setCaptchaError(false)


    if(!login || !password || !captchaValue) {
        if(!login) {
            setLoginError(true)
        }
        if(!password) {
            setPasswordError(true)
        }
        if(!captchaValue) {
            setCaptchaError(true)
        }
        return
    }

    const response = await authRequest({
        'email': login,
        'password': password
    })
    if(response.accessToken && response.refreshToken) {
        localStorage.setItem('access_token', response.accessToken)
        localStorage.setItem('refresh_token', response.refreshToken)
        navigate('/')
    }
  }

  return (
    <>
      <div className={styles.login_title}>Вход</div>
      <form onSubmit={handleSubmit} className={styles.login_content}>
        <div className={styles.input_container}>
          <span>Email</span>
          <input
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            className={loginError && styles.input_error}
            type="text"
            placeholder="Введите ваш email"
            name="email"
          />
          {loginError && (
            <div className={styles.error_container}>
              <img src={error_icon} className={styles.error_icon} alt="" />{" "}
              Данного пользователя не существует
            </div>
          )}
        </div>
        <div className={styles.input_container} style={{ marginBottom: "20px" }}>
          <span>Пароль</span>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={passwordError && styles.input_error}
            type={showPassword ? "password" : "text"}
            placeholder="Введите пароль"
            name="password"
          />
          <img
            className={styles.show_password_icon}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            style={{ cursor: "pointer" }}
            src={showPassword ? show_password : hide_password}
            alt="password"
          />
          {passwordError && (
            <div className={styles.error_container}>
              <img src={error_icon} className={styles.error_icon} alt="" />{" "}
              Неверный пароль
            </div>
          )}
        </div>
        <ReCAPTCHA
          sitekey="6Ldf1MMqAAAAAI3gIcOVKFep3lWxSQiCm3OY3Ijr"
          onChange={handleCaptchaChange}
        />
        {captchaError && (
          <div className={styles.error_container}>
            <img src={error_icon} className={styles.error_icon} alt="" />{" "}
            Пожалуйста, подтвердите, что вы не робот
          </div>
        )}
        <button onClick={handleSubmitSignIn}>Вход</button>
        <span style={{ cursor: "pointer" }}>
          <NavLink to="/login/recover">Забыли пароль ?</NavLink>
        </span>
      </form>
    </>
  );
}

export default SignIn;
