import styles from './EmployeeAddModal.module.css'
import modal_header_icon from '../../../assets/EmployeesPage/modal_header_icon.png'
import ModalInfo from '../ModalInfo/ModalInfo';
import { useEffect, useState } from 'react';
import { createWorkerRequest } from '../../../api/requests';
import JobTitle from '../JobTitle/JobTitle';
import Checkbox from '../../Checkbox/Checkbox';
import user_icon from '../../../assets/MainPage/user_icon.png'

function EmployeeAddModal({closeModal, resetWorkers, currentJobs}) {
  const [workHours, setWorkHours] = useState({
    morning: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    },
    day: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    },
    evening: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    },
    night: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    }
  })
  const [info, setInfo] = useState({
    name: '',
    birthdate: '',
    phone: '',
    email: '',
    passportNumber: '',
    number1c: '',
    internalid: ''
  })
  const [canChange, setCanChange] = useState(false)
  const [choosedJob, setChoosedJob] = useState([]);
  const [jobs, setJobs] = useState(currentJobs);
  const [isFired, setIsFired] = useState(false);

  useEffect(() => {
    if(info.name.split(' ')[0] && (info.name.split(' ')[1] || info.name.split(' ')[2]) && !info.name.split(' ')[3]) {
      setCanChange(true)
    } else {
      setCanChange(false)
    }
  }, [info.name]);

  const addJob = (val) => {
    setChoosedJob((prevChoosedJob) => 
      prevChoosedJob.includes(val) 
        ? prevChoosedJob.filter((el) => el !== val) 
        : [...prevChoosedJob, val]
    );
  };

  const updateWorkHours = (period, field, value) => {
    setWorkHours(prevWorkHours => ({
      ...prevWorkHours,
      [period]: {
        ...prevWorkHours[period],
        [field]: value
      }
    }));
  };

  const updateInfo = (field, value) => {
    setInfo((prevInfo) => ({
        ...prevInfo,
        [field]: value,
    }));
  };

  let stylesForRate = {
    gap: '70px',
    disable: false,
    first: {
      first: {
        title: 'Ставка (утро)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      second: {
        title: 'Ставка переработки (утро)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      third: {
        title: 'Кол. часов (утро)',
        placeholder: '8 ч.',
        width: '143px'
      },
      fourth: {
        title: 'Период (утро)',
        placeholder: 'С__:__ До__:__',
        width: '181px'
      },
    },
    second: {
      first: {
        title: 'Ставка (день)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      second: {
        title: 'Ставка переработки (день)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      third: {
        title: 'Кол. часов (день)',
        placeholder: '8 ч.',
        width: '143px'
      },
      fourth: {
        title: 'Период (день)',
        placeholder: 'С__:__ До__:__',
        width: '181px'
      },
    },
    third: {
      first: {
        title: 'Ставка (вечер)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      second: {
        title: 'Ставка переработки (вечер)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      third: {
        title: 'Кол. часов (вечер)',
        placeholder: '8 ч.',
        width: '143px'
      },
      fourth: {
        title: 'Период (вечер)',
        placeholder: 'С__:__ До__:__',
        width: '181px'
      },
    },
    fourth: {
      first: {
        title: 'Ставка (ночь)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      second: {
        title: 'Ставка переработки (ночь)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      third: {
        title: 'Кол. часов (ночь)',
        placeholder: '8 ч.',
        width: '143px'
      },
      fourth: {
        title: 'Период (ночь)',
        placeholder: 'С__:__ До__:__',
        width: '181px'
      },
    },
  }

  const addEmployeeFunc = async () => {
    let data = {
      firstname: info.name.split(' ')[1],
      lastname: info.name.split(' ')[0], 
      secondname: info.name.split(' ')[2],
      birthdate: info.birthdate,
      phone: info.phone,
      email: info.email,
      isfired: isFired,
      passportnumber: String(info.passportNumber),
      number1c: String(info.number1c),
      internalid: String(info.internalid),
      workerappointments: choosedJob
    };
    setInfo({
      name: '',
      birthdate: '',
      phone: '',
      email: '',
      passportNumber: '',
      number1c: '',
      internalid: ''
    })

    setIsFired(false)
    setChoosedJob([])

    await createWorkerRequest(data)

    closeModal(false)
    resetWorkers()
  }

  return (
    <div className={styles.background_container} onClick={() => closeModal(false)}>
      <div className={styles.main_container} onClick={(event) => event.stopPropagation()}>
        <div className={styles.main_header}>
          <span>
            Добавить сотрудника
          </span>
          <img src={modal_header_icon} alt="" onClick={() => closeModal(false)}/>
        </div>
        <div className={styles.main_content}>
          <div className={styles.content_header}>
            <div className={styles.header_name}>
              {/* <div className={styles.header_name_img}></div> */}
              <img className={styles.header_name_img} src={user_icon} alt="" />
              <input type="text" value={info.name} onChange={(e) => updateInfo('name', e.target.value)} placeholder='Введите ФИО'/>
            </div>
            <div className={styles.header_jobtitle_container}>
              <div className={styles.title}>
                Должность
              </div>
              <JobTitle jobs={jobs} choosedJob={choosedJob} addJob={addJob}  width={356}/>
            </div>
          </div>
          <ModalInfo info={info} handleInfo={updateInfo}/>
          {/* <div className={styles.rates_container}>
            <div className={styles.rates_title}>Ставки</div>
            <div className={styles.rates_second}>
              <div className={styles.rates_second_name}>
                <span>Повар</span>
                <span>Шеф повар</span>
              </div>
              <div className={styles.rates_second_checkbox}>
                <input type="checkbox" />
                Индивидуальные условия
              </div>
            </div>
            <div className={styles.rates_title}>
              <RatesTime content={stylesForRate} fields={workHours} handleFields={updateWorkHours}/>
            </div>
          </div> */}
        </div>
        <div className={styles.buttons_container}>
          <div className={styles.fired}>
            <Checkbox value={isFired} valueChange={() => setIsFired(!isFired)} />
            Уволен
          </div>
          <div className={styles.buttons}>
            <button className={styles.delete} onClick={() => closeModal(false)}>
              Удалить
            </button>
            <button style={{background: canChange && 'green'}} className={styles.save} onClick={addEmployeeFunc}>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeAddModal;
