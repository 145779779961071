import styles from './RegistryPage.module.css';
import RatesTime from './../../EmployeesPage/RatesTime/RatesTime';
import { useEffect, useState } from 'react';
import { changeJobTitle, createJobTitle, deleteAppointment, getAppointmentsRequest, getTime, getWorkersByAppointment,  } from '../../../api/requests';

function RegistryPage() {
  const [error, setError] = useState('');
  const [currentJobTitle, setCurrentJobTitle] = useState(0);
  const [activeStatuses, setActiveStatuses] = useState([]);
  const [workersLoading, setWorkersLoading] = useState(true);
  const [workers, setWorkers] = useState([]);
  const [deleteAppointmentModal, setDeleteAppointmentModal] = useState('');
  const [newElem, setNewElem] = useState(false);
  const [jobTitles, setJobTitles] = useState([{
    name: '',
    number1c: '',
    new: true,
    description: '',
    rates: {
      morning: {
        firstInput: '',
        secondInput: '',
        thirdInput: '',
        fourthInput: ''
      },
      day: {
        firstInput: '',
        secondInput: '',
        thirdInput: '',
        fourthInput: ''
      },
      evening: {
        firstInput: '',
        secondInput: '',
        thirdInput: '',
        fourthInput: ''
      },
      night: {
        firstInput: '',
        secondInput: '',
        thirdInput: '',
        fourthInput: ''
      }
    }
  }]);

  const transformRatesToInputs = (data) => {
    return data.map((appointment) => {
      const rates = {
        morning: { firstInput: '', secondInput: '', thirdInput: '', fourthInput: '' },
        day: { firstInput: '', secondInput: '', thirdInput: '', fourthInput: '' },
        evening: { firstInput: '', secondInput: '', thirdInput: '', fourthInput: '' },
        night: { firstInput: '', secondInput: '', thirdInput: '', fourthInput: '' }
      };

      // Заполняем ставки в зависимости от workshiftid
      appointment.hourrates.forEach(rate => {
        switch (rate.workshiftid) {
          case 1: // Утро
            rates.night.firstInput = rate.ratenormative;
            rates.night.secondInput = rate.rateexcess;
            rates.night.thirdInput = rate.rateholiday;
            rates.night.fourthInput = rate.rateholidayexcess;
            break;
          case 2: // День
            rates.morning.firstInput = rate.ratenormative;
            rates.morning.secondInput = rate.rateexcess;
            rates.morning.thirdInput = rate.rateholiday;
            rates.morning.fourthInput = rate.rateholidayexcess;
            break;
          case 3: // Вечер
            rates.day.firstInput = rate.ratenormative;
            rates.day.secondInput = rate.rateexcess;
            rates.day.thirdInput = rate.rateholiday;
            rates.day.fourthInput = rate.rateholidayexcess;
            break;
          case 4: // Ночь
            rates.evening.firstInput = rate.ratenormative;
            rates.evening.secondInput = rate.rateexcess;
            rates.evening.thirdInput = rate.rateholiday;
            rates.evening.fourthInput = rate.rateholidayexcess;
            break;
          default:
            break;
        }
      });

      return {
        id: appointment.id,
        name: appointment.name,
        number1c: appointment.number1c,
        description: appointment.description,
        rates
      };
    });
  };

  // Функция для преобразования `rates` обратно в формат base, excess, и т.д.
  const transformInputsToRates = (rates) => {
    return Object.keys(rates).reduce((acc, period) => {
      const rate = rates[period];
      acc[period] = {
        base: parseFloat(rate.firstInput),
        excess: parseFloat(rate.secondInput),
        holiday: parseFloat(rate.thirdInput),
        holidayexcess: parseFloat(rate.fourthInput)
      };
      return acc;
    }, {});
  };

  const fetchData = async () => {
    let res = await getAppointmentsRequest();
    const transformedAppointments = transformRatesToInputs(res);
    if(res && res.length !== 0) {
      setJobTitles(transformedAppointments);
    }
  };

  useEffect(() => {    
    const fetchDataAsync = async () => {
      await fetchData();
      setActiveStatuses(await getTime());
    };
    fetchDataAsync();
  }, []);

  let stylesForRate = {
    gap: '16px',
    disable: false,
    first: {
      first: {
        title: 'Ставка',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      second: {
        title: 'Ставка пер.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      third: {
        title: 'Выходной',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      fourth: {
        title: 'Переработка вых.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
    },
    second: {
      first: {
        title: 'Ставка',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      second: {
        title: 'Ставка пер.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      third: {
        title: 'Выходной',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      fourth: {
        title: 'Переработка вых.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
    },
    third: {
      first: {
        title: 'Ставка',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      second: {
        title: 'Ставка пер.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      third: {
        title: 'Выходной',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      fourth: {
        title: 'Переработка вых.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
    },
    fourth: {
      first: {
        title: 'Ставка',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      second: {
        title: 'Ставка пер.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      third: {
        title: 'Выходной',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      fourth: {
        title: 'Переработка вых.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
    },
  };

  const updaterates = (period, field, value) => {
    setError('')
    setJobTitles((prevJobTitles) => {
      const updatedJobTitles = prevJobTitles.map((jobTitle, index) => {
        if (index !== currentJobTitle) return jobTitle;
        return {
          ...jobTitle,
          rates: {
            ...jobTitle.rates,
            [period]: {
              ...jobTitle.rates[period],
              [field]: value,
            },
          },
        };
      });
      return updatedJobTitles;
    });
  };

  const updateInfo = (field, value) => {
    setError('')
    setJobTitles((prevJobTitles) => {
      const updatedJobTitles = [...prevJobTitles];
      updatedJobTitles[currentJobTitle] = {
        ...updatedJobTitles[currentJobTitle],
        [field]: value,
      };
      return updatedJobTitles;
    });
  };

  const createJobTitleFunc = async () => {
    setError('')
    const { name, number1c, rates } = jobTitles[currentJobTitle];

    if (!name.trim()) {
      setError("Поле 'Название' не должно быть пустым");
      return;
    }
  
    const periodNames = {
      morning: "Утро",
      day: "День",
      evening: "Вечер",
      night: "Ночь"
    };
    
    const periods = ["morning", "day", "evening", "night"];
    for (let period of periods) {
      const { firstInput, secondInput, thirdInput, fourthInput } = rates[period];
      if (firstInput === '' || secondInput === '' || thirdInput === '' || fourthInput === '') {
        setError(`Все ставки в разделе '${periodNames[period]}' должны быть заполнены`);
        return;
      }
    }
    
    let ratesObject = transformInputsToRates(jobTitles[currentJobTitle].rates)
    if(!activeStatuses[1]?.isActive) {
      delete ratesObject.morning;
    }
    if(!activeStatuses[2]?.isActive) {
      delete ratesObject.day;
    }
    if(!activeStatuses[3]?.isActive) {
      delete ratesObject.evening;
    }
    if(!activeStatuses[0]?.isActive) {
      delete ratesObject.night;
    }

    let data = {
      name: jobTitles[currentJobTitle].name,  // Пример данных
      number1c: `${jobTitles[currentJobTitle].number1c}`, // Пример данных
      description: jobTitles[currentJobTitle].description, // Пример данных
      rates: transformInputsToRates(jobTitles[currentJobTitle].rates)
    };

    if (jobTitles[currentJobTitle].new) {
      const { new: _, ...cleanedData } = data;
      await createJobTitle(cleanedData);
      fetchData()
    } else {
      changeJobTitle(data, jobTitles[currentJobTitle].id);
    }
  };
  
  const addJobTitle = () => {
    let rates = {
      morning: {
        firstInput: activeStatuses[1].isActive ? '' : 0,
        secondInput: activeStatuses[1].isActive ? '' : 0,
        thirdInput: activeStatuses[1].isActive ? '' : 0,
        fourthInput: activeStatuses[1].isActive ? '' : 0
      },
      day: {
        firstInput: activeStatuses[2].isActive ? '' : 0,
        secondInput: activeStatuses[2].isActive ? '' : 0,
        thirdInput: activeStatuses[2].isActive ? '' : 0,
        fourthInput: activeStatuses[2].isActive ? '' : 0
      },
      evening: {
        firstInput: activeStatuses[3].isActive ? '' : 0,
        secondInput: activeStatuses[3].isActive ? '' : 0,
        thirdInput: activeStatuses[3].isActive ? '' : 0,
        fourthInput: activeStatuses[3].isActive ? '' : 0
      },
      night: {
        firstInput: activeStatuses[0].isActive ? '' : 0,
        secondInput: activeStatuses[0].isActive ? '' : 0,
        thirdInput: activeStatuses[0].isActive ? '' : 0,
        fourthInput: activeStatuses[0].isActive ? '' : 0
      }
    }
    setJobTitles([...jobTitles, { 
      new: true,
      name: '',
      number1c: '',
      description: '',
      rates
     }]);
     setCurrentJobTitle(jobTitles.length)

     document.querySelector('#nameInput').focus()
  };

  let getWorkers = async () => {
    setWorkers([])

    if(jobTitles.find((el, index) => index == currentJobTitle) && jobTitles.find((el, index) => index == currentJobTitle).id) {
      let res = await getWorkersByAppointment(jobTitles.find((el, index) => index == currentJobTitle).id)
      setWorkers(res)  
    }

    setWorkersLoading(false)
  }

  useEffect(() => {    
      setWorkersLoading(true)
      if(deleteAppointmentModal || deleteAppointmentModal === 0) {
        setNewElem(false)
      if(jobTitles.find((el, index) => index == currentJobTitle).new) {
        setNewElem(true)
      }
      getWorkers()
    }
  }, [deleteAppointmentModal, jobTitles]);

  return (
    <div className={styles.container}>
        <div className={styles.first_part}>
        {jobTitles.map((el, index) => (
          <button 
            style={{background: index !== currentJobTitle && 'transparent', color: index !== currentJobTitle && 'rgb(116, 116, 116)', border: index !== currentJobTitle && '1px solid rgb(179, 179, 179)'}} 
            className={styles.first_container} 
            onClick={() => setCurrentJobTitle(index)}
            key={index}
          >
            {el.name ? el.name : "Название должности"}
          </button>
        ))}
        </div>
        {jobTitles[currentJobTitle] && 
        <div className={styles.second_part}>
            <div className={styles.second_title_container}>
                <div className={styles.second_title_content}>
                    <div className={styles.second_input} style={{flex: 2}}>
                        <span>Название</span>
                        <input 
                          type="text" 
                          value={jobTitles[currentJobTitle].name} 
                          placeholder='Название должности' 
                          onChange={(e) => updateInfo('name', e.target.value)}
                          id="nameInput"
                        />
                    </div>
                    <div className={styles.second_input} style={{flex: 1}}>
                        <span>Номер 1С </span>
                        <input 
                          type="text" 
                          value={jobTitles[currentJobTitle].number1c} 
                          placeholder='Введите номер' 
                          onChange={(e) => updateInfo('number1c', e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles.second_title_content}>
                    <div className={styles.second_input} style={{marginTop: '25px', width: '100%'}}>
                        <span>Описание</span>
                        <textarea 
                          type="text" 
                          value={jobTitles[currentJobTitle].description} 
                          style={{height: '123px', padding: '12px 16px'}} 
                          placeholder='Введите текст' 
                          onChange={(e) => updateInfo('description', e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.rates_container}>
                <RatesTime 
                  key={currentJobTitle} // добавлено для корректного рендера
                  content={stylesForRate} 
                  fields={jobTitles[currentJobTitle].rates} 
                  handleFields={updaterates}
                  activeStatuses={activeStatuses}
                />
            </div>
        </div>
        }
        <div className={styles.buttons_container}>
          <button onClick={addJobTitle} className={styles.buttons_container_button}>Добавить новую должность</button>
          <div className={styles.rates_button_container}>
              <button className={styles.rates_button} onClick={createJobTitleFunc}>
                  Сохранить
              </button>
              {error && <div style={{color: 'red'}}>{error}</div>}
          </div>
          <button className={styles.rates_button} onClick={() => {setDeleteAppointmentModal(currentJobTitle)}}>
              Удалить должность
          </button>
        </div>

        {deleteAppointmentModal !== '' ? <div className={styles.background_container} onClick={() => {setDeleteAppointmentModal('')}}>
          <div className={styles.main_container} onClick={(event) => event.stopPropagation()}>
            {!workers || workersLoading ?
            <div className={styles.center_elem}>
              {
                newElem ? 
                <>Этот элемент не сохранен</>
                :
                <>Загрузка...</>
              }
            </div>
            :
            <div className={styles.main_con}>
              <div className={styles.main_con_header}>
                При удалении должности "{jobTitles.find((el, index) => index == currentJobTitle).name}" следующим сотрудникам нужно назначить другую должность
              </div>
              <div className={styles.main_con_content}>
                {workers && workers.length != 0 && workers.map((el) => {
                  return (
                    <div key={el.id}>
                      {el.fullname}
                    </div>
                  )
                })}
              </div>
              <button onClick={() => {currentJobTitle > 0 && setCurrentJobTitle(currentJobTitle - 1); setDeleteAppointmentModal(''); setJobTitles(jobTitles.filter((el, index) => index != currentJobTitle)); deleteAppointment(jobTitles.find((el, index) => index == currentJobTitle).id);}}>
                Удалить должность
              </button>
            </div>
            }
          </div>
        </div>
        :
        <></>}
    </div>
  );
}

export default RegistryPage;
