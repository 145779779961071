import axios from './axiosConfig';


export const getAccessToken = async() => {
  return {
    "Authorization" : "Bearer " + localStorage.getItem('access_token')
  }
}


// Общая функция для GET-запросов
export const getRequest = async (url) => {
  try {
    const response = await axios.get(url, {headers: await getAccessToken()});
    return response.data;
  } catch (error) {
    if (error.response) {
      // Обрабатываем ошибки с сервером
      const statusCode = error.response.status;
      const errorMessage = error.response.data?.message || 'Неизвестная ошибка на сервере';

      // Логирование ошибки
      console.error(`Error ${statusCode}: ${errorMessage}`);

      return {
        statusCode,
        error: errorMessage
      };
    }

    // Обработка ошибок сети или других неизвестных ошибок
    console.error('Network or unknown error:', error);
    throw new Error('Неизвестная ошибка');
  }
};


// Общая функция для POST-запросов
export const postRequest = async (url, data, config = {}) => {
  try {
    // Выполняем POST-запрос
    const response = await axios.post(url, data, {headers: await getAccessToken(), ...config});
    return response.data;
  } catch (error) {
    // Обработка ошибок
    if (error.response) {
      const statusCode = error.response.status;
      const errorMessage = error.response.data?.message || 'Неизвестная ошибка на сервере';

      // Логируем ошибку
      console.error(`Error ${statusCode}: ${errorMessage}`);

      return {
        statusCode,
        error: errorMessage
      };
    }

    // Обработка ошибок сети или других неизвестных ошибок
    console.error('Network or unknown error:', error);
    throw new Error('Неизвестная ошибка');
  }
};

// Общая функция для PATCH-запросов
export const patchRequest = async (url, data) => {
  try {
    // Выполняем PATCH-запрос
    const response = await axios.patch(url, data, {headers: await getAccessToken()});
    return response.data;
  } catch (error) {
    // Обработка ошибок
    if (error.response) {
      const statusCode = error.response.status;
      const errorMessage = error.response.data?.message || 'Неизвестная ошибка на сервере';

      // Логируем ошибку
      console.error(`Error ${statusCode}: ${errorMessage}`);

      return {
        statusCode,
        error: errorMessage
      };
    }

    // Обработка ошибок сети или других неизвестных ошибок
    console.error('Network or unknown error:', error);
    throw new Error('Неизвестная ошибка');
  }
};

// Общая функция для DELETE-запросов
export const deleteRequest = async (url) => {
  try {
    // Выполняем DELETE-запрос
    const response = await axios.delete(url, {headers: await getAccessToken()});
    return response.data;
  } catch (error) {
    // Обработка ошибок
    if (error.response) {
      const statusCode = error.response.status;
      const errorMessage = error.response.data?.message || 'Неизвестная ошибка на сервере';

      // Логируем ошибку
      console.error(`Error ${statusCode}: ${errorMessage}`);

      return {
        statusCode,
        error: errorMessage
      };
    }

    // Обработка ошибок сети или других неизвестных ошибок
    console.error('Network or unknown error:', error);
    throw new Error('Неизвестная ошибка');
  }
};

export const authRequest = async (data)  => {
  return await postRequest('/auth/signin', data)
};

// Получение всех сотрудников
export const getWorkersRequest = async () => {
  return await getRequest('/worker')
};

// Получение всех должностей
export const getAppointmentsRequest = async () => {
  return await getRequest('/company/appointment')
}

// Создание сотрудника
export const createWorkerRequest = async (data) => {
  return await postRequest('/worker', data)
};

// Обновление сотрудника
export const updateWorkerRequest = async (id, data) => {
  return await patchRequest('/worker/' + id, data)
};

// Удаление сотрудника
export const removeWorkerRequest = async (id) => {
  return await deleteRequest('/worker/' + id)
}

// Обновление параметров суток
export const resetTime = async (data) => {
  return await patchRequest('/company/hournorm/', data)
}

// Создание должности
export const createJobTitle = async (data) => {
  return await postRequest('/company/appointment/', data)
}

// Изменение должности
export const changeJobTitle = async (data, id) => {
  return await patchRequest('/company/appointment/' + id, data)
}

// Получение параметров суток
export const getTime = async () => {
  return await getRequest('/company/hournorm/')
}

// Обновление статуса дня
export const updateDayStatusWorker = async (data, date) => {
  return await patchRequest('/workday/' + date, data)
}

// Получение всех статусов за месяц
export const getStatusesByMonth =  async (day) => {
  return await getRequest('/workday/' + day)
}

export const createDayStatusWorker = async (data) => {
  return await postRequest('/workday', data)
}

export const getStats = async (date) => {
  return await getRequest(`/stat/${date}`)
}

export const getWorkersFilterRequest = async (data) => {
  return await postRequest('/worker/filter', data)
}

export const getUserProfile = async () => {
  return await getRequest('/user/profile')
}

export const changeUserProfile = async (data) => {
  return await patchRequest('/user/profile', data)
}

export const changePassword = async (data) => {
  return await postRequest('/user/change-password', data)
}

export const closeDay = async (date) => {
  return await postRequest('/workday/close/' + date)
}

export const sendCodeToEmail = async (data) => {
  return await postRequest('/auth/send-recovery-code', data, {
    skipAuthInterceptor: true,
  });
};

export const sendCodeRecovery = async (data) => {
  return await postRequest('/auth/confirm-recovery-code', data, {
    skipAuthInterceptor: true,
  });
};

export const resetPassword = async (data) => {
  return await postRequest('/auth/reset-password', data, {
    skipAuthInterceptor: true,
  });
};

export const deleteAppointment = async (id) => {
  return await deleteRequest('/company/appointment/' + id)
}

export const getWorkersByAppointment = async (id) => {
  return await getRequest('/worker/get-by-appointment-id/' + id)
}