import {Navigate, redirect, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import main_img from '../../assets/LoginPage/main_page.png'
import SignIn from '../../components/LoginPage/SignIn/SignIn';
import styles from './LoginPage.module.css'
import RecoverPassword from '../../components/LoginPage/RecoverPassword/RecoverPassword.jsx';
import NewPassword from '../../components/LoginPage/NewPassword/NewPassword.jsx';

function LoginPage() {
    const location = useLocation(); // получаем текущий путь
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    if(isAuthorized()) {
        return <Navigate replace to="/"/>
    }

    function isAuthorized() {
        return !!(localStorage.getItem('access_token') && localStorage.getItem('refresh_token'));
    }

    return (
        <div className={styles.login_container}>
            <img src={main_img} alt="main_img"/>
            <div className={styles.login_content}>
                {location.pathname == '/login' && <SignIn styles={styles}/>}
                {location.pathname == '/login/recover' && <RecoverPassword styles={styles}/>}
                {location.pathname == '/login/newpassword' && <NewPassword styles={styles}/>}
            </div>
        </div>
    );
}

export default LoginPage;