import { useEffect, useRef, useState } from 'react';
import styles from './AddEmployeeModal.module.css'; // Импорт стилей
import useClickOutside from '../../UseClickOutside/UseClickOutside';
import closeIcon from '../../../assets/Table/close_addempoyee_icon.svg'
import JobTitle from '../../EmployeesPage/JobTitle/JobTitle';
import { createWorkerRequest } from '../../../api/requests';

function AddEmployeeModal({seeModal, setAddEmployeeModal, jobsFromMain, updateData}) {
    const [choosedJob, setChoosedJob] = useState([]);
    const [jobs, setJobs] = useState([{id: 0, name: 'Директор'}, {id: 1, name: 'Работник'}, {id: 2, name: 'Повар'}, {id: 0, name: 'Директор'}, {id: 1, name: 'Работник'}, {id: 2, name: 'Повар'}, {id: 0, name: 'Директор'}, {id: 1, name: 'Работник'}, {id: 2, name: 'Повар'}]);

    useEffect(() => {
        let fetchData = async () => {
            if(jobsFromMain) {
                setJobs(jobsFromMain)
            } 
        }
        fetchData()
    }, []);
    
    const addJob = (val) => {
        setChoosedJob((prevChoosedJob) => 
        prevChoosedJob.includes(val) 
            ? prevChoosedJob.filter((el) => el !== val) 
            : [...prevChoosedJob, val]
        );
    };

    let saveEmployee = async () => {
        let data = {
            firstname: document.querySelector('#create_employee_input').value.split(' ')[1],
            lastname: document.querySelector('#create_employee_input').value.split(' ')[0], 
            secondname: document.querySelector('#create_employee_input').value.split(' ')[2],
            workerappointments: choosedJob
        };
    
        
        let res = await createWorkerRequest(data)
        updateData()
        setAddEmployeeModal()
    }

    const container = useRef()
    useClickOutside(container, setAddEmployeeModal);

    return (
        <>
            {seeModal && <div className={styles.main_container}>
            <div ref={container} className={styles.container}>
                <div className={styles.header}>
                    Добавить сотрудника
                    <img src={closeIcon} alt="" onClick={setAddEmployeeModal}/>
                </div>
                <hr className={styles.container_hr} style={{margin: '5px 0 20px 0'}}/>
                <input type="text" placeholder='ФИО' className={styles.input} id='create_employee_input'/>
                <div className={styles.container_title}>
                    Должность
                </div>
                <div className={styles.container_jobtitle}>
                    <JobTitle jobs={jobs} choosedJob={choosedJob} addJob={addJob} width={419}/>
                </div>
                <hr className={styles.container_hr} style={{margin: '321px 0 16px 0'}}/>
                <div className={styles.bottom_container}>
                    <button className={styles.first_button} onClick={setAddEmployeeModal}>Отмена</button>
                    <button className={styles.second_button} onClick={saveEmployee}>Сохранить</button>
                </div>
            </div></div>}
        </>
    );
}

export default AddEmployeeModal;