import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import error_icon from "../../../assets/LoginPage/error_icon.svg";
import { sendCodeRecovery, sendCodeToEmail } from "../../../api/requests";

function RecoverPassword({styles}) {
  const navigate = useNavigate();
  let [email, setEmail] = useState('')
  let [code, setCode] = useState('')
  
  let [codeSended, setCodeSended] = useState(false)
  let [codeError, setCodeError] = useState(false)
  
  let sendEmailFunc = async () => {
    if(!email) {
        return;
    }
    let data = {
        email
    }
    let res = await sendCodeToEmail(data)
    if(!res.error) {
        setCodeSended(true)
    }
  }

  let sendCodeFunc = async () => {
    setCodeError(false)
    if(!code) {
        setCodeError(true)
        return;
    }
    let data = {
        email,
        code
    }

    let res = await sendCodeRecovery(data)
    if(!res.error) {
        localStorage.setItem('email', email)

        navigate('/login/newpassword');
    } else {
        setCodeError(true)
    }
  }

  return (
    <>
    <div className={styles.login_title}>
        Востановление пароля
    </div>
    {codeSended ? <>
        <div className={styles.input_container}>
            <span>Код</span>
            <input value={code} onChange={(e) => setCode(e.target.value)} className={codeError && styles.input_error} type="text" placeholder="Введите код"/>
            {codeError && <div className={styles.error_container}>
                <img src={error_icon} className={styles.error_icon} alt="" /> Неправильный код
            </div>}
        </div>
        <button onClick={(e) => {
            e.preventDefault();
            sendCodeFunc();
        }}>
            Подтвердить код
        </button>
    </>
    :
    <>
        <div className={styles.input_container}>
            <span>Email</span>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Введите ваш email"/>
        </div>
        <button onClick={(e) => {
            e.preventDefault();
            sendEmailFunc();
        }}>
            Отправить код
        </button>
    </>}
    <span style={{cursor: 'pointer'}}>
        <NavLink to="/login">
            Войти в личный кабинет
        </NavLink>
    </span>
    </>
  );
}

export default RecoverPassword;
